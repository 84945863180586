/* COLORS */
:root {
  --darkGreen: #16302b;
  --midGreen: #85b79d;
  --lightGreen: #c0e5c8;
  --darkPurp: #694873;
  --lightPurp: #8b728e;
}

* {
  cursor: crosshair !important;
}

body {
  background-color: var(--lightGreen);
}

.App {
  text-align: center;
  font-family: "Nanum Gothic Coding";
  font-weight: 800;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  width: 80px;
  height: 90%;
}

.navParent {
  display: flex;
  flex-direction: row;
  background-color: var(--midGreen);
  justify-content: space-between;
  gap: 10px;
  height: 50px;
  padding: 5px;
  margin-bottom: 5px;
  box-shadow: 3px 4px 3px 0px black;
}

.logoGroup {
  display: flex;
  flex-direction: row;
  width: 400px;
  height: 50px;
  margin-inline: 5px;
  gap: 10px;
  color: var(--lightGreen);
  filter: drop-shadow(3px 3px var(--darkGreen));
}

.userInfoSection {
  margin: auto;
  margin-left: 0px;
  font-family: "Permanent Marker";
  font-size: 2.2rem;
  color: var(--darkPurp);
  filter: drop-shadow(3px 3px var(--darkGreen));
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  width: 350px;
  height: 50px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding-right: 10px;
}

.buttonGroup Button {
  padding: 5px;
  width: 10%;
  background-color: var(--lightPurp);
  box-shadow: 3px 4px 3px 0px var(--darkGreen);
}

/* TABLE */

.tableParent {
  width: 98vw;
  background-color: var(--lightPurp);
  height: 80vh;
  box-shadow: 3px 4px 3px 0px black;
  margin: auto;
  margin-top: 20px;
  border: 3px solid var(--darkGreen);
}

/* Login */

.formHeader {
  font-size: 1.3em;
}

.loginParent {
  margin-top: 10%;
}

.mb-3 {
  margin-bottom: 10px;
}

.mb-3 input {
  margin-top: 5px;
  width: 250px;
  height: 20px;
  background-color: var(--darkPurp);
  color: var(--lightGreen);
  font-weight: 600;
  border: 2px solid var(--darkGreen);
  box-shadow: 2px 2px 2px 0px var(--darkGreen);
  caret-color: var(--lightGreen);
  cursor: crosshair;
}

.form-control:focus {
  box-shadow: 2px 2px 2px 0px black !important;
  outline: none;
}

.loginButton {
  width: 120px;
  height: 50px;
  box-shadow: 3px 4px 3px 0px var(--darkGreen);
  border: 2px solid var(--darkGreen);
  color: var(--midGreen);
  background-color: var(--lightPurp);
  font-weight: 500;
  font-family: "Permanent Marker";
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 1.8em;
  text-shadow: 2px 1px var(--darkPurp);

  animation: MoveUpDown 1s alternate infinite;
  -webkit-animation: MoveUpDown 1s alternate infinite;
}

.loginButton:focus {
  background-color: var(--darkPurp);
}

@keyframes MoveUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.attributeSection {
  
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px;
  font-size: 1.4rem;
  font-family: "Permanent Marker";
  text-shadow: 2px 3px var(--lightGreen);
  border: 3px solid var(--darkGreen);
  margin: 8px;
  background-color: var(--lightPurp);
  box-shadow: 2px 2px 2px 0px black;
  color: var(--darkGreen);
}
